hlc-results-container {
	background-color: var(--clr-color-neutral-0);
	width: 100%;
	max-width: 600px;
	max-height: 11rem;
	.hlc-typeahead-results-container {
		height: 100%;
		overflow-y: auto;
		padding-right: 1rem;
	}
}

hlc-result-item {
	.hlc-typeahead-result-item {
		&.active {
			background-color: var(--clr-color-action-100);
		}
		&:hover {
			background-color: var(--clr-color-neutral-100);
		}
	}
}
