// // Clarity Dependency SCSS
// @import "../node_modules/@clr/ui/src/utils/dependencies.clarity"; // Includes light theme

@import '@clr/ui/clr-ui.min.css';
@import '@clr/icons/clr-icons.min.css';

//my theme
@import './theme.scss';
// // Clarity Component SCSS
// @import "@clr/ui/src/utils/variables/_variables.global.scss";
// @import "@clr/ui/src/utils/variables/_variables.layout.scss";

//ng-select adaptation
@import './clarity-ng-select.scss';

//Typeahead style fixes
@import './hlc.scss';

// regular style toast
@import 'ngx-toastr/toastr';

//CDK styles
@import '@angular/cdk/overlay-prebuilt.css';

.cdk-global-overlay-wrapper {
	z-index: 2000;
}

.cdk-overlay-container {
	.cdk-overlay-connected-position-bounding-box {
		z-index: 3000;
	}
}

// fonts
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro&display=swap');

//Quill editor
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';
@import 'quill-placeholder-module/dist/toolbar.css';

quill-editor {
	display: flex;
	flex-direction: column;

	.ql-toolbar {
		background-color: whitesmoke;
	}

	.ql-container {
		height: auto;
		max-height: 500px;
		background-color: white;

		p {
			font-size: 10pt !important;
			margin: 0 !important;
		}

		.ql-editor {
			max-height: 500px;
			// min-height: 350px;
		}
	}

	.ql-picker-options {
		max-height: 12rem;
		overflow-y: auto;
		overflow-x: hidden;
	}
}

//Scheduler
@import '@syncfusion/ej2-base/styles/material.css';
@import '@syncfusion/ej2-buttons/styles/material.css';
@import '@syncfusion/ej2-calendars/styles/material.css';
@import '@syncfusion/ej2-dropdowns/styles/material.css';
@import '@syncfusion/ej2-inputs/styles/material.css';
@import '@syncfusion/ej2-lists/styles/material.css';
@import '@syncfusion/ej2-popups/styles/material.css';
@import '@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css';
@import '../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-treegrid/styles/material.css';
@import '../node_modules/@syncfusion/ej2-gantt/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-calendars/styles/material.css';

ejs-datetimepicker {
	.e-input-group.e-control-wrapper.e-datetime-wrapper {
		border: none !important;
		margin: 0 !important;

		input {
			padding: 0 !important;
		}

		.e-clear-icon {
			padding: 0;

			&.e-clear-icon-hide {
				display: block;
				visibility: hidden;
			}
		}

		.e-input-group-icon.e-icons {
			margin-bottom: 0;

			&.e-active {
				color: var(--clr-color-action-500) !important;
			}
		}

		&::before {
			content: none !important;
		}

		&::after {
			content: none !important;
		}
	}
}

ejs-datetimepicker.clr-input:focus-within {
	border-bottom-color: var(--clr-forms-focused-color);
	background-size: 100% 100%;
}

.clr-error ejs-datetimepicker.clr-input:focus-within {
	background-size: 100% 100%;
}

.e-calendar .e-header .e-icon-container {
	.e-prev .e-date-icon-prev, .e-date-icon-next {
		&::before {
			display: block;
			transform: rotate(-90deg);
		}
	}
}

.card>.card-block>.card-title {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.clr-accordion-content {
	overflow: visible;
}

clr-icon.ejs-fix {
	margin-bottom: 4px;
}

table {
	tbody:not(.no-hover) {
		tr:hover {
			background-color: var(--clr-vertical-nav-hover-bg-color);
		}
	}

	.clr-form-control.no-label {
		label {
			display: none !important;
		}
	}

	input {
		width: 100%;
		min-width: 5rem;
	}
}

// fix clarity date input
clr-date-container {
	input {
		width: auto !important;
	}
}

//common classes

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
	opacity: 0.5;
}

.modal-body {
	max-height: 70vh;
	overflow-x: clip;
	overflow-y: visible;

	> :first-child {
		margin-top: 0;
	}
}

.clr-input-wrapper {
	max-height: 100%;
}

.text-uppercase {
	text-transform: uppercase;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.text-bold {
	font-weight: bold;
}

header .settings>.dropdown>.dropdown-toggle,
header .header-actions>.dropdown>.dropdown-toggle,
.header .settings>.dropdown>.dropdown-toggle,
.header .header-actions>.dropdown>.dropdown-toggle {
	outline-offset: -0.07rem;
}

.btn .tooltip-content {
	text-transform: none;
}

.modal-dlg-header {
	display: flex;
	align-items: center;
	// height: 1rem;
	width: 100%;
	background-color: var(--clr-color-neutral-100);
	cursor: move;

	.drag-handle-icon {
		cursor: move;
	}

	.close-modal-btn {
		margin-left: auto;
	}

	h3 {
		margin: 0;
	}
}

.main-logo {
	height: 60%;
}

//768px is md in Clarity
@media screen and (max-width: 768px) {
	.main-logo {
		width: 100%;
	}
}

.button-file-input {
	display: none;
}

.section-with-button {

	h4,
	h3 {
		display: inline;
	}

	button {
		margin-left: 1em;
	}
}

.clickable {
	cursor: pointer;
}

.card-media-image>svg {
	width: auto !important;
	height: auto !important;
}

.datagrid-table {
	width: 100%;

	.datagrid-cell {
		text-overflow: ellipsis;
		overflow-x: hidden;
	}
}

clr-datagrid .datagrid-row-scrollable {
	min-width: 0; // fix flex issue
}

.table,
.grid {
	th[scope='row'] {
		border-bottom: none;
	}

	.left {
		text-align: left;
	}

	.right {
		text-align: right;

		.datagrid-column-title {
			text-align: right;
		}
	}

	td.right:not(.text) {
		font-family: 'Source Code Pro', monospace;
	}

	&.right {

		th:not(.left),
		clr-dg-cell:not(.left),
		td:not(.left) {
			text-align: right;
		}

		clr-dg-cell:not(.left),
		td:not(.left) {
			&:not(.text) {
				font-family: 'Source Code Pro', moospace;
			}

			input[type='number'] {
				text-align: right;
			}
		}
	}
}

.clr-input-wrapper {
	max-width: 100%;
}

a {
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

input[type='text'],
input[type='email'],
input[type='search'],
textarea.clr-textarea {
	width: 100%;
	max-width: 600px;
}

ng-select {
	max-width: 600px;
}

input[type='color'] {
	cursor: pointer;
}

input[type='color'].clr-input {
	border-bottom: none;
}

clr-input-container {
	&.clr-input-no-label {
		.clr-control-label {
			display: none;
		}
	}

	&.clr-input-align-right {
		input {
			float: right;
		}
	}

	&.clr-input-text-right {
		justify-content: end;

		input {
			text-align: right;
		}
	}

	&.clr-input-fit-width {
		input {
			max-width: 600px;
			width: 100%;
			min-width: 4rem;
		}
	}
}

.file-btn {
	position: relative;

	input[type='file'] {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
	}
}

.btn:active {
	background-color: var(--clr-btn-default-hover-bg-color, hsl(198, 83%, 94%));
	box-shadow: 0 0.1rem 0 0 var(--clr-color-action-500, #179bd3) inset;
}

.btn:hover {
	color: var(--clr-btn-default-active-color, hsl(198, 100%, 32%));
}

.btn.btn-primary:active {
	color: var(--clr-btn-primary-hover-color, #e3f5fc);
}

.btn.btn-success {

	&:hover,
	&:active {
		box-shadow: 0 0.1rem 0 0 var(--clr-btn-success-hover-bg-color, #3c8500) inset;
		background-color: var(--clr-btn-success-hover-bg-color, 3c8500);
	}

	&:active {
		box-shadow: 0 0.1rem 0 0 var(--clr-btn-success-active-bg-color, #255200) inset;
	}
}

.btn.btn-danger {

	&:hover,
	&:active {
		box-shadow: 0 0.1rem 0 0 var(--clr-btn-danger-hover-bg-color, #179bd3) inset;
		background-color: var(--clr-btn-danger-hover-bg-color, hsl(9, 100%, 38%));
	}

	&:active {
		box-shadow: 0 0.1rem 0 0 var(--clr-btn-danger-active-bg-color, #179bd3) inset;
	}
}

.btn.btn-warning {

	&:hover,
	&:active {
		box-shadow: 0 0.1rem 0 0 var(--clr-btn-warning-hover-bg-color, #179bd3) inset;
		background-color: var(--clr-btn-warning-hover-bg-color, hsl(9, 100%, 38%));
	}

	&:active {
		box-shadow: 0 0.1rem 0 0 var(--clr-btn-warning-active-bg-color, #179bd3) inset;
	}
}

.btn.btn-outline:active {
	color: var(--clr-btn-default-outline-hover-color, hsl(198, 100%, 24%));
	background-color: var(--clr-btn-default-outline-hover-bg-color, transparent);
}

.btn-link {

	&:active,
	&:hover {
		box-shadow: none;
	}
}

.column-toggle--action:hover {
	box-shadow: none;
}

.switcher {

	&:hover,
	&:active,
	&:focus {
		color: var(--clr-calendar-btn-color, hsl(198, 100%, 32%));
	}
}

.btn-sm.btn-icon.btn-sq {
	padding-left: 0.25rem;
	padding-right: 0.25rem;
}

.btn-sm.btn-icon.btn-rnd {
	border-radius: 50%;
	width: 2rem;
	height: 2rem;
	padding: 0;
}

.float-right {
	float: right;
}

.display-block {
	display: block;
}

.display-flex {
	display: flex;
}

.display-inline-block {
	display: inline-block;
}

.full-width {
	width: 100%;
}

.no-wrap {
	white-space: nowrap;
}

.right-0 {
	right: 0;
	position: absolute;
}

.clr-popover-content.datepicker {
	// https://github.com/vmware/clarity/issues/4272
	z-index: 9000;
}

.ngx-color-picker {
	width: 1.4rem;
	min-width: 1.4rem !important;
}

.page-header {
	margin: 0;

	h3 {
		margin-top: 0.7rem;
		margin-right: 0.7rem;
	}

	clr-input-container,
	clr-toggle-wrapper {
		margin-top: 0.6rem;
		margin-left: 1rem;
	}

	span {
		display: inline-flex;
		align-items: center;
	}
}

.main-container .content-container .content-area {
	padding-top: 0;
}

.toast-top-right {
	top: 3rem;
}

#ng-chat {
	bottom: -0.4rem !important;
}

.e-gantt .e-gantt-chart .e-chart-row .e-chart-row-cell {
	line-height: normal;
}

.sticky-actions {
	position: sticky;
	top: 0;
	float: right;
	margin-top: 0.4rem;
	z-index: 11;

	button:not(.btn-primary):not(.btn-danger):not(.btn-danger-outline),
	clr-button {
		background-color: floralwhite;
	}
}

a.inactive-item {
	color: #cc0000;
}

.cursor-default {
	cursor: default;
}

.bid-item-input-container {
	margin: 0 !important;

	.clr-control-container {
		padding: 0 !important;
	}
}

.clr-input:not([readonly]).bid-item-input,
.clr-input-group.bid-item-input {
	background:
		linear-gradient(to bottom, #fff8e4 95%, var(--clr-forms-focused-color) 95%) 0 0 no-repeat,
		#fff8e4;
	background-size: 0 100%;

	&:focus {
		background-size: 100% 100%;
	}

	&:focus-within {
		border-bottom-color: var(--clr-forms-focused-color);
		background-size: 100% 100%;
	}
}

.clr-input-group.bid-item-input .clr-input {
	background: none;
}

input[type='checkbox'].is-active-checkbox {
	&+label {
		padding: 0;
		min-height: auto;

		&::before {
			top: 0;
			background: #f27963;
		}

		&::after {
			content: none;
		}
	}

	&:checked+label {
		&::before {
			top: 0;
			background: #5aa220 !important;
		}

		&::after {
			content: none;
		}
	}

	&:disabled+label {
		&::after {
			content: '';
			height: .8rem;
    		width: .8rem;
			top: 0;
			left: 0;
			background: lightgray;
			opacity: 0.6;
			border: 1px solid black;
			border-radius: var(--clr-forms-checkbox-border-radius, 0.15rem);
			transform: none;
		}
	}
}

clr-dg-row.changed-row,
clr-dg-row.changed-row:hover {
	background: #fff8e4;
}

clr-dg-cell,
clr-dg-column {
	min-width: 6.3rem !important;
}

clr-dg-column {
	.clear-filter-button {
		display: none;
		position: relative;
		top: 1px;

		cds-icon {
			color: #c2786b !important;
		}

		&:hover {
			cds-icon {
				color: #991700 !important;
			}
		}
	}

	.datagrid-filtered+.clear-filter-button {
		display: inline-block;
	}
}

.zero-margin {
	margin: 0;
}

.pre {
	white-space: pre;
}

.no-space {
	height: 0;
	width: 0;
	padding: 0;

	>* {
		height: 0;
	}
}

.icon-danger {
	color: #991700;
}

.icon-success {
	color: #008000;
}

.btn .clr-loading-btn-content {
	justify-content: center;
}

.switch-content {
	.tooltip {
		overflow: hidden;
	}
}

.e-tooltip-wrap {
	max-height: 300px !important;
	overflow: auto;

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: darkgray;
		border-radius: 2px;

		&:hover {
			background-color: rgb(141, 139, 139);
		}
	}
}

.tooltip__event {
	width: 100%;
	overflow: auto;

	&-name {
		margin-bottom: 3px;
		font-size: 1.1em;
		border-bottom: 1px solid white;
	}

	&-sequences {
		display: flex;
		flex-direction: column;
		gap: 2px;
	}

	&-sequence {
		display: flex;
		justify-content: space-between;
		align-items: center;

		&-title {
			flex: 1 1 auto;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&-badges {
			flex: 0 0 auto;
			display: flex;
			gap: 0.3rem;
		}

		&-badge {
			display: flex;
			justify-content: center;
			align-items: center;

			span {
				margin-right: 0;
			}
		}
	}

	&-text-wrapper {
		border-top: 1px solid white;
	}

	&-text {
		white-space: pre-wrap;
	}
}

.clr-subtext {
	font-family: var(--clr-font, Metropolis, "Avenir Next", "Helvetica Neue", Arial, sans-serif) !important;
}

.thin-scrollbar {
	::-webkit-scrollbar {
		width: 5px;
	}

	::-webkit-scrollbar-thumb {
		background-color: darkgrey;
		border-radius: 2px;
	}
}

.thin-ng-select {

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
	.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
		padding: 0.2rem 0.2rem 0.2rem 0.4rem;
		white-space: normal;
		word-break: break-word;
		display: flex;
		align-items: center;
	}
}