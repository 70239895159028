@import '@clr/ui/src/utils/variables/_variables.color.scss';

/**
Clarity (http://clarity.design) (at the moment of writing) does not have an auto-complete/combobox component.
ng-select is currently one of the more popular angular2/4 packages for adding auto-complete components to an angular project.

This style sheet allows anyone to use ng-select in a angular+clarity project that uses clarity forms.
To get these styles applied to your ng-select component, declare your form component as follows:

<form clrForm>
  <clr-input-container>
    <label>Foo</label>
    <ng-select clrInput [items]="exampleItems"></ng-select>
  </clr-input-container>
</form>
 */

$ng-select-highlight: $clr-color-action-600 !default;
$ng-select-primary-text: rgba(black, 0.87) !default;
$ng-select-primary-light-text: rgba(white, 0.87) !default;
$ng-select-secondary-text: rgba(black, 0.54) !default;
$ng-select-secondary-light-text: rgba(white, 0.54) !default;
$ng-select-disabled-text: rgba(black, 0.38) !default;
$ng-select-divider: rgba(black, 0.12) !default;
$ng-select-bg: #ffffff !default;
$ng-select-placeholder: lighten($ng-select-primary-text, 40) !default;

.ng-select.ng-select-opened .ng-select-container {
	z-index: 1000 !important;
}

.ng-select {
	width: 100%;
	max-height: 100%;
	height: 100%;
	.ng-has-value .ng-placeholder {
		display: none;
	}
	ng-select {
		padding: 0;
		height: auto;
		max-height: none;
	}
	.clr-input-wrapper {
		max-height: none;
		clr-icon {
			position: absolute;
			right: -8px;
			top: 0;
		}
	}
	.ng-value-container {
		.ng-placeholder {
			color: $ng-select-placeholder;
		}
		.ng-value {
			border-radius: 10px;
			padding: 0 4px;
			margin: 1px 2px;
			line-height: 22px;
			max-height: 22px;
			min-width: 9rem;
		}
		.ng-value-icon {
			margin-right: 4px;
			margin-left: 8px;
			&:first-of-type {
				margin-left: 0;
			}
			display: inline-block;
		}
	}
	.ng-select-multiple {
		.ng-placeholder {
			top: 5px;
			padding-bottom: 5px;
			padding-left: 3px;
		}
		.ng-value-container {
			.ng-value {
				color: $ng-select-primary-light-text;
				background: $ng-select-highlight;
				padding: 0 8px;
			}
		}
		.ng-input {
			input {
				padding: 0 0 0 4px;
			}
		}
	}
	.ng-clear-wrapper {
		text-align: center;
		margin-top: 2px;
	}
}

// .dark-theme {
//   .ng-select {
//     input, .ng-value, .ng-clear-wrapper {
//       color: $ng-select-primary-light-text;
//     }
//   }
//   .ng-select-multiple {
//     .ng-value-container {
//       .ng-value {
//         background: $dark-theme-highlight-color;
//       }
//     }
//   }
// }
.modal-body {
	overflow: visible; //[#387] fix Chrome bug: different options for overflow-x and overflow-y don't work
}
.ng-dropdown-panel {
	z-index: 999 !important; //to don't cover modals which have z-index: 1050
	background: $ng-select-bg;
	left: 0;
	&.ng-select-bottom {
		top: calc(100%);
		box-shadow:
			0 5px 5px -3px rgba(0, 0, 0, 0.2),
			0 8px 10px 1px rgba(0, 0, 0, 0.14),
			0 3px 14px 2px $ng-select-divider;
	}
	&.ng-select-top {
		bottom: calc(100% - 0.84375em);
		box-shadow:
			0 -5px 5px -3px rgba(0, 0, 0, 0.2),
			0 -8px 10px 1px rgba(0, 0, 0, 0.14),
			0 -3px 14px 2px $ng-select-divider;
	}
	&.multiple {
		.ng-option {
			&.selected {
				background: $ng-select-bg;
			}
			&.marked {
				background: rgba(0, 0, 0, 0.04);
			}
		}
	}
	.ng-dropdown-header {
		border-bottom: 1px solid $ng-select-divider;
		padding: 0 16px;
		line-height: 3em;
		min-height: 3em;
	}
	.ng-dropdown-footer {
		border-top: 1px solid $ng-select-divider;
		padding: 0 16px;
		line-height: 3em;
		min-height: 3em;
	}
	.ng-dropdown-panel-items {
		max-height: 10rem !important;
		.ng-optgroup {
			user-select: none;
			cursor: pointer;
			line-height: 3em;
			height: 3em;
			padding: 0 16px;
			color: $ng-select-secondary-text;
			font-weight: 500;
			&.ng-option-marked {
				background: rgba(0, 0, 0, 0.04);
			}
			&.ng-option-disabled {
				cursor: default;
			}
			&.ng-option-selected {
				background: $ng-select-divider;
				color: $ng-select-highlight;
			}
		}
		.ng-option {
			display: flex !important;
			align-items: center;
			line-height: 3em;
			min-height: 3em;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding: 0 16px;
			text-decoration: none;
			position: relative;
			color: $ng-select-primary-text;
			text-align: left;
			&.ng-option-marked {
				background: rgba(0, 0, 0, 0.04);
				color: $ng-select-primary-text;
			}
			&.ng-option-selected {
				background: $ng-select-divider;
				color: $ng-select-highlight;
			}
			&.ng-option-disabled {
				color: $ng-select-disabled-text;
			}
			&.ng-option-child {
				padding-left: 32px;
			}
			.ng-tag-label {
				padding-right: 5px;
				font-size: 80%;
				font-weight: 400;
				color: $ng-select-disabled-text;
			}
			input[type='checkbox'] {
				margin-right: 0.24rem;
			}
		}
	}
}
