:root {
	--clr-color-hue: 51;

	--blue-base-color-hue: 201;

	--clr-color-action-50: #fffce8;
	--clr-color-action-100: #fef3b5;
	--clr-color-action-200: #ffe860;
	--clr-color-action-300: #ffdc0b;
	--clr-color-action-400: #fdd006;
	--clr-color-action-500: #fac400;
	--clr-color-action-600: #febd16; // value from clarity palette: #EDB200;
	--clr-color-action-700: #dfa100;
	--clr-color-action-800: #febd16; // value from clarity palette: #D28F00;
	--clr-color-action-900: #c47d00;
	--clr-color-action-1000: black;

	//textarea
	--clr-outline-color: hsl(var(--clr-color-hue), 68%, 65%);
	--clr-calendar-btn-picker-trigger-color: var(--clr-color-action-600);

	//header
	--clr-header-6-bg-color: white;
	--clr-header-font-color: black;
	--clr-header-font-color-hover: black;
	--clr-header-search-input-font-color: #000;

	//buttons
	--clr-btn-default-color: black;
	--clr-btn-default-border-color: var(--clr-color-action-800);

	--clr-btn-primary-color: black;
	--clr-btn-primary-bg-color: var(--clr-color-action-800);

	--clr-btn-default-outline-color: black;
	--clr-btn-default-outline-border-color: var(--clr-color-action-800);

	--clr-btn-link-color: var(--clr-color-action-800);
	--clr-btn-default-hover-color: black;
	--clr-btn-primary-active-bg-color: var(--clr-btn-primary-bg-color);
	--clr-btn-danger-active-bg-color: var(--clr-color-danger-900);
	--clr-btn-warning-active-bg-color: var(--clr-color-danger-900);
	--clr-btn-danger-hover-bg-color: var(--clr-color-danger-800);
	--clr-btn-warning-hover-bg-color: var(--clr-color-danger-800);
	// --clr-calendar-btn-hover-focus-color: var(--clr-global-hover-color)

	//links
	// $clr-link-active-color: $clr-color-secondary-action-500 !default;
	--clr-link-color: hsl(var(--blue-base-color-hue), 100%, 36%);
	--clr-link-hover-color: var(--clr-link-color);
	// $clr-link-visited-color: hsl(238, 41%, 53%) !default;
	// $clr-custom-links-hover-color: $clr-color-neutral-200 !default;

	// //icons color
	--clr-forms-focused-color: var(--clr-color-action-800);
	--clr-forms-border-color: #b3b3b3;
	--clr-forms-border-disabled-color: #b3b3b3;
	--clr-forms-placeholder-color: #8c8c8c;

	// //badge
	--clr-badge-info-bg-color: var(--clr-color-action-800);
	--clr-badge-info-color: black;

	// nav
	--clr-nav-active-bg-color: transparent;
	--clr-nav-horizontal-gap-size: 0 1.2rem;

	--clr-datagrid-column-switch-header-font-color: var(--clr-btn-link-color);

	--clr-vertical-nav-item-child-padding: 0 0.6rem 0 2.3rem;
}

.radio.btn input[type='radio']:checked + label {
	background-color: var(--clr-color-action-800);
}

.main-container:not(.open-hamburger-menu) {
	.header-hamburger-trigger {
		& > span,
		& > span::before,
		& > span::after {
			background: black;
		}
	}
}
